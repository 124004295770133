async function logErrorToGoogleSheet(errorMessage, errorDetails = "", source = "", address="") {
    const url = new URL("https://script.google.com/macros/s/AKfycbzmnsmDmpqi4UBMQX_b0Tsd6rFWbnH7Ao-ci09MOmH5nsGQqD2n3vQg_akXBciA_5Cv/exec"); // Replace with your Web App URL
  
  // Append query parameters
  url.searchParams.append("errorMessage", errorMessage);
  url.searchParams.append("errorDetails", errorDetails);
  url.searchParams.append("source", source);
  url.searchParams.append("address", address);

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    const result = await response.json();
    if (result.status === "success") {
      console.log("Error logged successfully");
    } else {
      console.error("Failed to log error:", result.message);
    }
  } catch (err) {
    console.error("Failed to send error log:", err);
  }
}

  export default logErrorToGoogleSheet;
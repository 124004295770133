import uploadImage from '../Upload/upload-image.js';

/**
 * Attempt to upload the original file, but fallback to downsized
 * if uploading exceeds maxDuration (in milliseconds).
 */
async function uploadWithFallback({
    originalFile,
    downsizedFile,
    setProgress,
    index,
    address,
    type,
    path,
    maxDuration = 10000, // e.g. 10 seconds
  }) {
    if (!downsizedFile) {
      // If for some reason there is no downsized file, just upload the original
      return uploadImage(originalFile, setProgress, index, address, type, path);
    }
  
    return new Promise((resolve, reject) => {
      let timedOut = false;
      const controller = new AbortController();
  
      // 1) Set up a timeout to abort the upload if it takes too long
      const timeout = setTimeout(() => {
        timedOut = true;
        controller.abort();
      }, maxDuration);
  
      // 2) Try uploading the original
      uploadImage(downsizedFile, setProgress, index, address, type, path, controller.signal)
        .then((result) => {
          if (!timedOut) {
            // Upload succeeded within time
            clearTimeout(timeout);
            resolve(result);
          }
        })
        .catch((err) => {
          clearTimeout(timeout);
          if (timedOut) {
            console.log(`Original upload timed out. Fallback to downsized...`);
            // 3) Upload the downsized file
            uploadImage(downsizedFile, setProgress, index, address, type, path)
              .then((res2) => resolve(res2))
              .catch((err2) => reject(err2));
          } else {
            // If we failed for any other reason (not a timeout), reject
            reject(err);
          }
        });
    });
  }

export default uploadWithFallback;
  